<template>
  <crud-form
    :schema="schema"
    :model="form"
    :path="'store'"
    :form-title="$t('addStore')"
    :redirect-route="'StoreList'"
  />
</template>

<script>
  import CrudForm from '../../components/CrudForm';
  import { schema } from './schema/store'
  import { form } from './forms/store'

  export default {
    name: 'AddStore',
    components: {
      CrudForm
    },
    data: () => {
      return {
        schema,
        form,
      }
    },
  }
</script>
